import React from 'react'
import { graphql } from 'gatsby'
import propTypes from 'prop-types'
import Layout from '../components/layout'
import Hero from '../components/Hero'
import PatternCard from '../components/PatternCard'
import PatternGrid from '../components/PatternGrid'
import ContentWrapper from '../components/ContentWrapper'
import SectionHeading from '../components/SectionHeading'
import ShareBanner from '../components/ShareBanner'

export const query = graphql`
  {
    patternInfo: contentfulContentTypeInfo(contentType: { eq: "pattern" }) {
      categoryPageDefinition {
        json
      }
      icon {
        file {
          url
        }
      }
      contentType
    }
    shareBanner: contentfulShareBanner(type: { eq: "pattern" }) {
      heading
      bodyText
      iconPosition
      icon {
        title
        file {
          url
        }
      }
    }
    principles: allContentfulPrinciple {
      nodes {
        name
        slug
        heroIcon {
          file {
            url
          }
          description
        }
      }
    }
    patterns: allContentfulPattern(sort: { fields: createdAt, order: DESC }) {
      nodes {
        name
        slug
        summary
        relatedPrinciples {
          slug
        }
      }
    }
  }
`

const PatternsPage = ({
  data: { patternInfo, principles, patterns, shareBanner },
}) => (
  <Layout title="Browse patterns">
    <Hero
      heading="Browse patterns"
      heroImage={patternInfo.icon && patternInfo.icon}
      description={patternInfo.categoryPageDefinition}
    />
    <ContentWrapper>
      {principles.nodes.map((principle) => (
        <section key={principle.name}>
          <SectionHeading image={principle.heroIcon}>
            {principle.name}
          </SectionHeading>
          <PatternGrid>
            {patterns.nodes
              .filter((pattern) =>
                pattern.relatedPrinciples.some(
                  (el) => el.slug === principle.slug
                )
              )

              .map((relatedPattern) => (
                <PatternCard
                  key={relatedPattern.slug}
                  slug={relatedPattern.slug}
                  name={relatedPattern.name}
                  summary={relatedPattern.summary}
                />
              ))}
          </PatternGrid>
        </section>
      ))}
    </ContentWrapper>
    {shareBanner && (
      <ShareBanner
        heading={shareBanner.heading}
        image={shareBanner.icon}
        position={shareBanner.iconPosition}
        bodyText={shareBanner.bodyText}
      />
    )}
  </Layout>
)

PatternsPage.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
}

export default PatternsPage
